<template>
  <div class="my-5 text-center">
    <div class="d-flex flex-wrap justify-content-center">
      <div v-for="(navbar, index) in navbarData.filter(p=>p.mobile==true)" :key="index++" 
        class="d-flex row  align-items-stretch flex-grow-1">
        
        <template v-if="navbar.dropdown">
          <div class="col-12 p-0 m-0 fs-5">
            {{ navbar.title }}
            <hr>
          </div>
          <div v-for="(dropdownItem, dropdownIndex) in navbar.dropdown"
             :key="dropdownIndex" class="col-xl-4 col-4 p-0 m-0 ">
            <div class="shadow rounded m-1 py-3">
              <router-link :to="{ name: dropdownItem.name }"
              class="py-xl-1 px-xl-2 text-decoration-none text-dark ">
                  <i :class="`${dropdownItem.icon}`" class="text-secondary"></i>
                  <div>
                    {{ dropdownItem.title }}
                  </div>
              </router-link>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="col-xl-4 col-4 p-0 m-0 ">
            <div class="shadow rounded m-1 py-3 ">
              <router-link
                  :to="{ name: navbar.name }"
                  :class="$route.name.includes(navbar.slug) ? 'active' : ''"
                  class="py-xl-1 px-xl-2 text-decoration-none text-dark "
                >
                  <i :class="`${navbar.icon}`" class="text-secondary"></i>
                  <div>
                    {{ navbar.title }}
                  </div>
              </router-link>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import navbar from "../../data/navbar/index.json";
export default {
  data() {
    return {
      navbarData: navbar,
    };
  },
  methods: {
    
  },
};
</script>
<style scoped>
.bi {
  font-size: 14px !important;
  margin: 0;
}
.bi-logo {
  font-size: 23px !important;
  margin: 0;
}
@media (max-width: 767px) {
  .bi {
    font-size: 32px !important;
    margin: 0;
  }
}
</style>
